import { Button, Card, Layout, Result, Spin, Typography } from "antd";
import Html5QrcodePlugin from "./qr";
import { useState } from "react";
import Login from "./login";

function App() {
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userFound, setUserFound] = useState(false);

  async function fetchEmail(text) {
    try {
      const response = await fetch(`${process.env.REACT_APP_ENDPOINT}users/qraccess/`, {
        headers: { Authorization: `Token ${window.location.hash.slice(1)}`, 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ email: text }),
      });
      console.log(response);
      if (response.status !== 200) {
        setLoading(false);
        setUserFound(false);
      } else {
        setLoading(false);
        setUserFound(true);
      }
    } catch(e) {
      console.error(e);
      setLoading(false);
      setUserFound(false);
    }
  }

  const onNewScanResult = async (decodedText, decodedResult) => {
    console.log(decodedText, decodedResult);
    setEmail(decodedText);
    setLoading(true);
    fetchEmail(decodedText);
  };

  return (
    <Login>
      {!email && (
        <Html5QrcodePlugin
          key="test"
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}
        />
      )}
      {email && (
        <Layout style={{ height: '100vh' }}>
          <Layout.Content>
              <Card style={{ textAlign: 'center' }}>
                <Typography.Title>Trovato</Typography.Title>
                <Typography.Text>{email}</Typography.Text>
                <div style={{ marginTop: '1rem' }}>
                  {loading && <Spin />}
                  {!loading && userFound && (
                    <Result 
                      status="success"
                      title="Accesso registrato"
                    />
                  )}
                  {!loading && !userFound && (
                    <Result 
                      status="error"
                      title="Accesso fallito"
                    />                  
                  )}
                </div>
                {!loading && (
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                    <Button onClick={() => setEmail(null)}>Torna alla scansione</Button>
                    {!userFound && (
                      <Button onClick={() => fetchEmail(email)} color="warning">Riprova</Button>
                    )}
                  </div>
                )}
              </Card>
          </Layout.Content>
        </Layout>
      )}
    </Login>
  );
}

export default App;
